@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://unpkg.com/leaflet@1.9.4/dist/leaflet.css");

@import url("https://fonts.googleapis.com/css?family=Cabin");

@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";
body {
  font-family: "Cabin", sans-serif;
}
@import "@smastrom/react-rating/style.css";
.active {
  color: white !important;
  @apply h-[52px] w-[52px] bg-[#30325E] rounded-full
    text-[24px] py-[15px] px-[14px];
}
/* In your main CSS file or App.css */
@import "react-big-calendar/lib/css/react-big-calendar.css";

.dx-scheduler-work-space-week .dx-scheduler-header-panel-cell,
.dx-scheduler-work-space-work-week .dx-scheduler-header-panel-cell {
  text-align: center;
  vertical-align: middle;
}

.dx-scheduler-work-space .dx-scheduler-header-panel-cell .name {
  font-size: 13px;
  line-height: 15px;
}

.dx-scheduler-work-space .dx-scheduler-header-panel-cell .number {
  font-size: 15px;
  line-height: 15px;
}
.css-1bzx5rb-MuiTableCell-root .Cell-dayOfWeek {
  font-family: "Cabin", "sans-serif" !important;
  font-weight: bold;
  font-size: 1rem !important;
  line-height: 1.66;
  letter-spacing: 0.03333em;
  margin: 0;
  padding: 8px;
  padding-bottom: 0;
  color: rgba(0, 0, 0, 0.6);
}
@media (min-width: 600px) {
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-height: auto;
    font-family: "Cabin", "sans-serif" !important;
  }
}
.css-1675apn-MuiTableCell-root .Cell-dayOfMonth {
  font-family: "Cabin", "sans-serif" !important;
  font-weight: bold !important;
  font-size: 1.5rem !important;
}
.css-1675apn-MuiTableCell-root .Cell-dayOfWeek {
  font-family: "Cabin", "sans-serif" !important;
  font-weight: bold !important;
}
.css-1fbp63r .Label-text {
  font-family: "Cabin", "sans-serif" !important;
  font-weight: bold !important;
  font-size: 1rem !important;
}
.css-11ngvp6 .Content-lens {
  color: #30325e !important;
}
.css-11ngvp6 .Content-dateAndTitle {
  line-height: 1 !important;
  font-family: "Cabin" !important;
}
/* .css-nupfgw-MuiGrid-root.Layout-root {
  display: none !important;
} */
.css-vr9er9-MuiModal-root-MuiDrawer-root .Overlay-halfSize {
  height: 100% !important;
  width: 450px !important;
}

.user_page_filter {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* For Chrome, Safari, Edge */
.sidebar_div::-webkit-scrollbar {
  width: 0; /* Hide vertical scrollbar */
  height: 0; /* Hide horizontal scrollbar */
}

/* For Firefox */
.sidebar_div {
  scrollbar-width: none; /* Hide scrollbar in Firefox */
}

/* For Internet Explorer/Edge (Legacy) */
.sidebar_div {
  -ms-overflow-style: none; /* IE and Edge */
}
.sidebar_div {
  padding-top: 20px;
}

.drivers_table td {
  word-wrap: break-word;
  word-break: break-all;
  white-space: normal;
}
.css-1x9erwy-MuiPaper-root {
  width: 100%;
}

/* responsive media queries */
@media only screen and (max-width: 600px) {
  .drivers_table td {
    word-wrap: normal;
    word-break: normal;
    white-space: nowrap;
  }
  .drivers_rides_section {
    display: flex;
    flex-direction: column;
  }
  .drivers_table_parent {
    width: 100%;
    overflow: auto;
  }
  .progress_parent {
    margin: 0;
  }
  .boxes_parent div {
    padding: 10px 15px;
    font-size: 44px;
  }
  .boxes_parent h3 {
    font-size: 28px;
  }
  .boxes_parent h1 {
    font-size: 35px;
  }

  .parent_home,
  .driv_top {
    padding: 0 10px;
  }
  .siteLogo img {
    height: 100px;
    width: 100%;
    object-fit: cover;
  }
  .mb_nav {
    width: 100%;
  }
  .filter_parent,
  .filter_input,
  .drivers_parent {
    gap: 10px;
    flex-direction: column;
  }
  .user-page {
    padding: 0;
  }
  .filter_input,
  .filter_input_p,
  .d_filter,
  .driver_filter {
    width: 100%;
  }
  .filter_input input,
  .dots_btn,
  .add_new_user_btn {
    font-size: 14px;
    padding: 0;
    padding: 10px;
  }
  .user_page_filter {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .r-wi-100 {
    width: 100%;
  }
  .add_new_user_btn {
    width: 100%;
    text-align: center;
  }
  .box-border {
    font-size: 50px;
  }
  .nav_toggle_handle {
    height: 50px;
  }
  .user_name {
    font-size: 22px;
  }
  .mobile_sidebar_toggle h2 {
    font-size: 24px;
  }

  .mobile_sidebar_toggle .nv_item {
    border-bottom: 1px solid gray;
  }
  .meeting_page,
  .upcoming_meeting_div .react-datepicker,
  .react-datepicker__month-container {
    width: 100%;
  }
  .meeting_top {
    padding: 10px;
  }
  .driver_filter {
    flex-direction: column;
  }
  .driver_page_heading,
  .setting_page_title {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .h-screen {
    height: auto;
  }
  .trips_page {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
  }
  .trips_page button {
    font-size: 14px;
    text-align: center;
    margin-right: 0;
    display: flex;
    justify-content: center;
    padding: 10px;
  }
  .rides_page {
    flex-direction: column;
    align-items: flex-start;
  }
  .r-top {
    padding: 10px;
  }
  .css-2mawfw {
    flex-direction: column;
    width: 100%;
  }
  .pending_payment {
    display: flex;
    padding: 10px;
    justify-content: space-between;
  }
  .settings_page,
  .payment_page,
  .up_plan,
  .payment_page_bottom {
    padding: 10px;
  }
  .pay_button {
    margin-right: 0;
  }
  .plan_page_heading {
    flex-direction: column;
    align-items: flex-start;
  }
  .payment_page_heading {
    font-size: 25px;
  }
  .rdrDateRangePickerWrapper {
    flex-direction: column;
    width: 100% !important;
  }
  .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper,
  .css-o3ooc0-MuiContainer-root,
  .css-1x9erwy-MuiPaper-root {
    width: 100% !important;
  }
  .css-1x9erwy-MuiPaper-root {
    padding: 10px !important;
  }
  .css-o3ooc0-MuiContainer-root {
    padding: 0 !important;
  }
  .dashboard_filter {
    width: 100%;
    font-size: 22px;
    padding: 10px;
  }
  .css-2mawfw {
    width: 100% !important;
  }
  .css-3su884-MuiPaper-root {
    width: 100% !important;
    max-width: 100% !important;
    min-height: 100%;
  }
  .trip_review_heading {
    margin-left: 0 !important;
  }
  .review_top {
    gap: 10px;
    flex-direction: column !important;
  }
  .search_review {
    padding: 20px;
    width: 100% !important;
    margin-right: 0 !important;
    border: 1px solid gray !important;
  }

  .driv_top {
    width: 100%;
    max-width: 100%;
  }
  .driver_parent {
    margin-top: 70px;
  }
  .driver_parent div:first-child {
    /* height: 100% !important; */
    padding: 0 !important;
    background-color: white;
  }
}
